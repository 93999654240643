<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.addListingHeader') }}</b>
    </h3>
    <hr />
    <div v-html="$t('FAQ.addListingQ1')"></div>
    <br />
    <div v-html="$t('FAQ.addListingAns1')"></div>
    <hr />
    <div v-html="$t('FAQ.desktop')"></div>
    <ol>
      <li v-html="$t('howItWorks.general.step1')"></li>
    </ol>
    <div class="wrapper--img">
      <img
        src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing.png"
        alt=""
      />
    </div>
    <ol start="2">
      <li v-html="$t('howItWorks.general.step2')"></li>
    </ol>
    <div class="wrapper--img">
      <img
        src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/data_add_listing.png"
        alt=""
      />
    </div>
    <ol start="3">
      <li v-html="$t('howItWorks.general.step3')"></li>
    </ol>
    <div class="wrapper--img">
      <img
        src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing_lanjutkan.png"
        alt=""
      />
    </div>
    <ol start="4">
      <li v-html="$t('howItWorks.general.step4')"></li>
    </ol>
    <div class="wrapper--img">
      <img
        src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing_publish.png"
        alt=""
      />
    </div>
    <ol start="5">
      <li v-html="$t('howItWorks.general.step5')"></li>
      <li v-html="$t('howItWorks.general.step6')"></li>
    </ol>
    <hr />
    <h6>
      <b>{{ $t('howItWorks.general.stepMobile') }}</b>
    </h6>
    <div class="wrapper--img">
      <img
        src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/menu_mobile.png"
        alt=""
      />
    </div>
    <ol>
      <li v-html="$t('howItWorks.general.stepM1')"></li>
    </ol>
    <div class="wrapper--img">
      <img
        src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing_mobile.png"
        alt=""
      />
    </div>
    <ol start="2">
      <li v-html="$t('howItWorks.general.stepM2')"></li>
      <li v-html="$t('howItWorks.general.step3')"></li>
      <li v-html="$t('howItWorks.general.step4')"></li>
      <li v-html="$t('howItWorks.general.step5')"></li>
      <li v-html="$t('howItWorks.general.step6')"></li>
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
