var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_c('b',[_vm._v(_vm._s(_vm.$t('FAQ.addListingHeader')))])]),_c('hr'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('FAQ.addListingQ1'))}}),_c('br'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('FAQ.addListingAns1'))}}),_c('hr'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('FAQ.desktop'))}}),_c('ol',[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step1'))}})]),_vm._m(0),_c('ol',{attrs:{"start":"2"}},[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step2'))}})]),_vm._m(1),_c('ol',{attrs:{"start":"3"}},[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step3'))}})]),_vm._m(2),_c('ol',{attrs:{"start":"4"}},[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step4'))}})]),_vm._m(3),_c('ol',{attrs:{"start":"5"}},[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step5'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step6'))}})]),_c('hr'),_c('h6',[_c('b',[_vm._v(_vm._s(_vm.$t('howItWorks.general.stepMobile')))])]),_vm._m(4),_c('ol',[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.stepM1'))}})]),_vm._m(5),_c('ol',{attrs:{"start":"2"}},[_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.stepM2'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step3'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step4'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step5'))}}),_c('li',{domProps:{"innerHTML":_vm._s(_vm.$t('howItWorks.general.step6'))}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper--img"},[_c('img',{attrs:{"src":"https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper--img"},[_c('img',{attrs:{"src":"https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/data_add_listing.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper--img"},[_c('img',{attrs:{"src":"https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing_lanjutkan.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper--img"},[_c('img',{attrs:{"src":"https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing_publish.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper--img"},[_c('img',{attrs:{"src":"https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/menu_mobile.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper--img"},[_c('img',{attrs:{"src":"https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/cara-kerja/add_listing_mobile.png","alt":""}})])
}]

export { render, staticRenderFns }